@use "mixins" as *
.faqContainer
  composes: faqContainer from "components/pages/home/faq/home-faq.module.sass"

  *
    font-size: 14px
    line-height: 18px

  > div > section
    padding-right: 24px
    ul
      padding-top: 10px
